import { Tools } from "@/classes/viewer/tools";
import { LayerItem } from "@/classes/data-structures/layer-item";
import { LayerItemType } from "@/classes/data-structures/layer-item-type";
import { SceneElement } from "@/classes/data-structures/scene-element";
import { ViewType } from "@/classes/data-structures/view-type";
import { defineStore } from "pinia";
import { ViewIndex } from "@/classes/data-structures/view-index";
import { Project, Survey } from "@/swagger";
import { ref, watch } from "vue";
import { useDataStore } from "./data-store";
import { useRoute, useRouter } from "vue-router";
import { ProjectViewType as ProjectViewType } from "@/classes/data-structures/project-view-type";

export const useAppStateStore = defineStore("app-state",()=>{
	const dataStore = useDataStore();
	const route = useRoute();
	const router = useRouter();

	const projectViewType = ref<ProjectViewType>(ProjectViewType.Grid);
	const selectedTool = ref( Tools.Selection );
	const mapType = ref<"satellite" | "vector">( "vector");
	const splitViewType = ref<"split" | "sideBySide">( "split");
	const availableViewTypes = ref<ViewType[]>( [ViewType.View2D, ViewType.View3D]);
	const viewType = ref<ViewType>( ViewType.View3D);
	const selectedElement = ref<SceneElement | null>( null);
	const activeCadLayer = ref<LayerItem | null>( null);
	const view1Layers = ref<LayerItem[]>( []);
	const view2Layers = ref<LayerItem[]>( []);
	const canCompareProject = ref( true);
	const canChangeMapType = ref(true);
	const project = ref<Project>( null);
	const view1Survey = ref<Survey>( null);
	const view2Survey = ref<Survey>( null);
	const view1Tab = ref<LayerItemType>(LayerItemType.Meshes);
	const view2Tab = ref<LayerItemType>(LayerItemType.Meshes);
	let lastLoadRequestId:number | undefined = null;

	function getViewTab(viewIndex: ViewIndex): LayerItemType {
		if(viewIndex == ViewIndex.View1){
			return view1Tab.value;
		}else{
			return view2Tab.value;
		}
	}

	function getViewLayers(viewIndex: ViewIndex, type?: LayerItemType): LayerItem[] {
		let layers = viewIndex == ViewIndex.View1 ? view1Layers.value : view2Layers.value;
		if (type) {
			layers = layers.filter((l) => l.layerType == type);
		}
		return layers;
	}

	function setViewTab(viewIndex: ViewIndex, tab: LayerItemType ) {
		if(viewIndex == ViewIndex.View1){
			view1Tab.value = tab;
		}else{
			view2Tab.value = tab;
		}
	}

	function setSelectedTool(tool: Tools) {
		selectedTool.value = tool;
	}

	function setAvailableViewTypes(viewTypes: ViewType[]) {
		availableViewTypes.value = viewTypes;
	}

	function setViewType(newViewType: ViewType) {
		viewType.value = newViewType;
	}

	function setMapType(newMapType: "satellite" | "vector") {
		mapType.value = newMapType;
	}

	function setProjectViewType(viewType: ProjectViewType){
		projectViewType.value = viewType;
		const query = Object.assign({},route.query);
		query.viewType = viewType;
		router.replace({query: query});
	}

	function setSelectedElement(element: SceneElement | null) {
		selectedElement.value = element;

		if (selectedElement.value && selectedElement.value.layerName) {
			const layer = view1Layers.value.find((l) => l.name === selectedElement.value.layerName && l.layerType == LayerItemType.Cad);
			if (layer) {
				setActiveCadLayer(layer);
			}
		}
	}

	function setActiveCadLayer(element: LayerItem | null) {
		activeCadLayer.value = element;
	}

	function setLayers(layers: LayerItem[], viewIndex: ViewIndex) {
		if (viewIndex == ViewIndex.View1) {
			view1Layers.value = layers;
		} else {
			view2Layers.value = layers;
		}
	}

	function toggleLayer(id: string, type: LayerItemType, view: ViewIndex = ViewIndex.View1) {
		const layers: LayerItem[] = view == ViewIndex.View1 ? view1Layers.value : view2Layers.value;

		const layer: LayerItem | null = layers.filter((l) => l.id == id && l.layerType == type)[0];

		if (layer) {
			layer.visible = !layer.visible;
		}
	}

	function updateElementName(element: SceneElement, title: string, viewIndex: ViewIndex) {
		(element as any).title = title;
		const layer = getViewLayers(viewIndex).filter((l) => l.id == element.uuid)[0];
		layer.name = title;
	}

	async function loadProject(projectUUID: string, surveyUUID?: string,survey2UUID?: string): Promise<boolean>{
		if(!projectUUID){
			project.value = null;
			view1Survey.value = null;
			view2Survey.value = null;
			return false;
		}
		const loadRequestId = Date.now();
		lastLoadRequestId = loadRequestId;

		console.log("Loading project", projectUUID, surveyUUID, loadRequestId);
		const projectData = await dataStore.getProject(projectUUID);

		//A new project load was called, therefore we should stop
		if(lastLoadRequestId != loadRequestId){
			console.log("A new loading load project was requested, cancelling", loadRequestId);
			return false;
		}
		project.value = projectData;
		// route.params.projectId = projectUUID; //<- do we need this, since we already update route in the end

		if (project.value.surveys.length > 0) {
			if(surveyUUID){
				const survey = project.value.surveys.find((s)=>s.uuid == surveyUUID);
				view1Survey.value = survey;
			}else if(route.name == "view"){
				view1Survey.value = project.value.surveys[project.value.surveys.length - 1];
			}else{
				view1Survey.value = null;
			}
			
			if(survey2UUID){
				const survey = project.value.surveys.find((s)=>s.uuid == survey2UUID);
				view2Survey.value = survey;
			}else{
				view2Survey.value = null;
			}
		} else {
			view1Survey.value = null;
			view2Survey.value = null
		}
		updateRoute();
		lastLoadRequestId = null;
		return true;
	}
	
	async function reloadCurrentProject(){
		return await loadProject(project.value.uuid, view1Survey.value?.uuid);
	}
	
	function setView1Survey(survey?: Survey){
		view1Survey.value = survey;
		updateRoute();
	}

	function setView2Survey(survey?: Survey){
		view2Survey.value = survey;
		updateRoute();
	}

	function updateRoute(){
		if(route.params.projectId !== project.value?.uuid || route.params.surveyId !== view1Survey.value?.uuid || route.params.surveyId2 !== view2Survey.value?.uuid){
			router.replace({ params: { projectId: project.value?.uuid, surveyId: view1Survey.value?.uuid, surveyId2: view2Survey.value?.uuid }, query: route.query })
		}
	}
	
	function loadProjectFromRouteParams(){
		const projectId = route.params.projectId as string;
		const surveyId = route.params.surveyId as string;
		const surveyId2 = route.params.surveyId2 as string;
		const viewType = route.query.viewType as ProjectViewType;

		if(viewType){
			setProjectViewType(viewType);
		}

		console.log("loadProjectFromRouteParams()", projectId, surveyId, surveyId2);

		loadProject(projectId, surveyId, surveyId2);
	}

	loadProjectFromRouteParams();
	
	watch(route, loadProjectFromRouteParams);

	return {
		//Variables
		getViewTab,
		setViewTab,
		selectedTool,
		mapType,
		splitViewType,
		availableViewTypes,
		viewType,
		projectViewType,
		selectedElement,
		activeCadLayer,
		view1Layers,
		view2Layers,
		canCompareProject,
		canChangeMapType,
		view1Survey,
		view2Survey,
		project,
		//Functions
		getViewLayers,
		setSelectedTool,
		setAvailableViewTypes,
		setViewType,
		setProjectViewType,
		setMapType,
		setSelectedElement,
		setActiveCadLayer,
		setLayers,
		toggleLayer,
		updateElementName,
		loadProject,
		reloadCurrentProject,
		setView1Survey,
		setView2Survey
	}
});
